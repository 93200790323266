import React, { ComponentType, FC, HTMLAttributes, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { Styled } from '../../types/common';
import { rem } from '../../utils/converters';
import { useForeground } from '../../utils/mixins';
import { styledProps } from '../../utils/object';

export type CardProps = PropsWithChildren<{
  as?: string | ComponentType<HTMLAttributes<Element>>,
  className?: string,
  clickable?: boolean,
  color?: string,
  role?: string,
  scale?: number,
  small?: boolean,
}>;

type StyledCardProps = Styled<Pick<CardProps, 'clickable' | 'color' | 'scale' | 'small'>>;

const clickableStyle = css`
  cursor: pointer;
`;

const smallStyle = css`
  border-radius: ${rem(6)};
`;

const Container = styled.div<StyledCardProps>`
  display: flex;
  flex-grow: 1;
  border-radius: ${rem(12)};

  ${({ _clickable, _color, _scale }) => useForeground({
    clickable: _clickable,
    color: _color,
    scale: _scale,
    shadow: true,
  })}
  ${({ _clickable }) => _clickable && clickableStyle}
  ${({ _small }) => _small && smallStyle}
`;

const Card: FC<CardProps> = ({
  as,
  children,
  className,
  clickable = false,
  color,
  role,
  scale,
  small,
}: CardProps) => (
  <Container
    {...styledProps({ clickable, color, scale, small })}
    as={as}
    className={className}
    role={role}
  >
    {children}
  </Container>
);

export default Card;

import React, { FC, PropsWithChildren } from 'react';
import { GatsbyLinkProps } from 'gatsby';

import Card, { CardProps } from '../card/card';
import Link from '../link/link';

type CardLinkOwnProps = CardProps & Pick<GatsbyLinkProps<{}>, 'to'> & {
  label?: string,
};

type CardLinkProps = PropsWithChildren<CardLinkOwnProps>;

const CardLink: FC<CardLinkProps> = ({ children, label, to, ...cardProps }: CardLinkProps) => (
  <Link to={to} aria-label={label}>
    <Card clickable={true} {...cardProps}>
      {children}
    </Card>
  </Link>
);

export default CardLink;

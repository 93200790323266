import React, { FC } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import { tagsDictionary } from '../../constants/pages';
import { rem } from '../../utils/converters';
import Text from '../text/text';
import { getTextColorForBackground } from '../../utils/colors';
import Card from '../card/card';
import { StylelessButton } from '../../utils/components';
import { useReactiveBackgroundColor } from '../../utils/mixins';

type StyledCardProps = {
  _color: string
};

const StyledTag = styled.li`
  display: inline-block;
  list-style: none;
  margin: 0;

  &:not(:last-child) {
    margin-right: ${rem(16)};
  }
`;

const StyledCard = styled(Card)<StyledCardProps>`
  padding: ${rem(6)} ${rem(8)};

  ${({ _color }) => useReactiveBackgroundColor({ color: _color })}
`;

const handleTagClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  event.preventDefault();
  navigate(`/blog/${event.currentTarget.dataset.id}/`);
};

export type TagsProps = {
  ids: string[],
};

const Tags: FC<TagsProps> = ({ ids }: TagsProps) => {
  if (ids.length === 0) return null;

  const tags = ids.map((tag) => tagsDictionary[tag]);

  return (
    <ul>
      {tags.map(({ id, tag, color }) => (
        <StyledTag key={id}>
          <StylelessButton onClick={handleTagClick} value={`Filter by "${id}" tag`} data-id={id}>
            <StyledCard _color={color} small={true}>
              <Text color={getTextColorForBackground(color)} primary={true} size={14}>{tag}</Text>
            </StyledCard>
          </StylelessButton>
        </StyledTag>
      ))}
    </ul>
  );
};

export default Tags;

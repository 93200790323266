import React, { FC } from 'react';
import { graphql } from 'gatsby';

import AppContainer from '../app-container/app-container';
import { ArticlePages } from '../../types/article';
import { Query } from '../../types/query';
import ArticlesList from '../articles-list/articles-list';
import { SEOData } from '../seo';
import { PageProps } from '../../types/pages';
import { tagsDictionary } from '../../constants/pages';

type TagIndexPageContext = {
  tagId: string,
};

export type TagIndexProps = Query<ArticlePages> & PageProps<TagIndexPageContext>;

const TagIndex: FC<TagIndexProps> = ({ data, pageContext }: TagIndexProps) => {
  const {
    allMdx: {
      nodes,
    },
  } = data;

  const { tagId } = pageContext;

  const { tag } = tagsDictionary[tagId];

  return (
    <AppContainer>
      <SEOData
        description={`A list of articles about ${tag}.`}
        title={`${tag} articles`}
      />
      <ArticlesList articles={nodes} />
    </AppContainer>
  );
};

export const pageQuery = graphql`
  query ArticlesByTag($tagId: String) {
    allMdx(
      filter: {
        frontmatter: {
          tags: {
            eq: $tagId
          }
        }
      }
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          tags
          date(formatString: "MMMM YYYY")
        }
      }
    }
  }
`;

export default TagIndex;

import React from 'react';
import styled from 'styled-components';

import { ArticleNode } from '../../types/article';
import CardLink from '../../ui/card-link/card-link';
import Text from '../../ui/text/text';
import { colors } from '../../utils/colors';
import { rem } from '../../utils/converters';
import { useFadeIn } from '../../utils/mixins';

import ArticleDetails from './article-details';

export type ArticleProps = {
  article: ArticleNode,
  fadeIndex?: number, // sets a fade-in animation delay if a number is provided
};

type ContainerProps = {
  _fadeIndex?: number,
};

const Container = styled.li<ContainerProps>`
  list-style: none;
  padding: 0;
  padding-bottom: ${rem(20)};

  ${({ _fadeIndex }) => Number.isInteger(_fadeIndex) && useFadeIn(
    { delay: (_fadeIndex! + 1) * 0.08 }
  )}
`;

const Content = styled.article`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: ${rem(24)};
`;

const StyledText = styled(Text)`
  margin-top: ${rem(16)};
`;
const Article = ({ article, fadeIndex }: ArticleProps) => {
  const {
    fields: {
      slug,
    },
    frontmatter: {
      title,
      description,
      tags,
      date,
    },
  } = article;

  return (
    <Container key={slug} _fadeIndex={fadeIndex}>
      <CardLink label={title} scale={1.02} to={slug}>
        <Content>
          <Text primary={true} size={24}>{title}</Text>
          <StyledText size={14} color={colors.text.quote}>{description}</StyledText>
          <ArticleDetails date={date} tags={tags} />
        </Content>
      </CardLink>
    </Container>
  );
};

export default Article;

import React, { FC } from 'react';

import { ArticleNode } from '../../types/article';
import Article from '../article';

export type ArticlesListProps = {
  articles: ArticleNode[],
};

const ArticlesList: FC<ArticlesListProps> = ({ articles }: ArticlesListProps) => (
  <ul>
    {articles.map((article, index) => (
      <Article
        article={article}
        fadeIndex={index}
        key={article.fields.slug}
      />
    ))}
  </ul>
);

export default ArticlesList;

import React, { FC, memo } from 'react';
import styled from 'styled-components';

import Tags from '../../ui/tags/tags';
import Text from '../../ui/text/text';
import { colors } from '../../utils/colors';
import { rem } from '../../utils/converters';
import { Article } from '../../types/article';

type ArticleDetailsProps = Pick<Article, 'date' | 'tags'>;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: ${rem(20)};
`;

const ArticleDetails: FC<ArticleDetailsProps> = ({ date, tags }: ArticleDetailsProps) => (
  <Details>
    <Tags ids={tags} />
    <Text color={colors.text.grey} size={14}>{date}</Text>
  </Details>
);

export default memo(ArticleDetails);
